@import url('../src/assets/style/variable.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font-aspekta-300 {
  font-family: var(--font-Aspekta-300) !important;
}
.font-aspekta-350 {
  font-family: var(--font-Aspekta-350) !important;
}
.font-aspekta-400 {
  font-family: var(--font-Aspekta-400) !important;
}
.font-aspekta-450 {
  font-family: var(--font-Aspekta-450) !important;
}
.font-aspekta-500 {
  font-family: var(--font-Aspekta-500) !important;
}
.font-aspekta-600 {
  font-family: var(--font-Aspekta-600) !important;
}
.font-aspekta-700 {
  font-family: var(--font-Aspekta-700) !important;
}
.cursor {
  cursor: pointer !important;
}
.main-container {
  display: flex;
  height: 100vh;
  flex-direction: row;
}
main {
  /* padding: 10px; */
  overflow: auto;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: #05162a;
  color: white;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
  white-space: nowrap;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-left: 0.25rem solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  border-left: 0.25rem solid #c8a864;
  background: #031120;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-left: 0.25rem solid #c8a864 !important;
  background: #031120;
  color: #c8a864 !important;
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;
  align-items: center;
  padding: 5px 10px;
  border-left: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
}
.VegaInput {
  border: none;
}
.VegaInput:focus {
  outline: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='number'] {
  -moz-appearance: textfield;
}
/* sx={{
  '.MuiPickersCalendarHeader-root': {},
  '.MuiPickersArrowSwitcher-root': {},
}} */
.MuiDayPicker-weekDayLabel {
  color: #031120 !important;
}
